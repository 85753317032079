<template>
  <div class="card">
    <a-page-header
      style="border: 1px solid rgb(235, 237, 240)"
      :title="classroom.name"
      :sub-title="$t('inscription.nbEleves', { number: classroomCount })"
      class="card-header"
      @back="() => $router.push('/inscriptionScolarite')"
    >
    </a-page-header>
    <div class="card-body">
      <h5 style="color: blue; font-weight: bold">
        {{ $t("inscription.inscription") }}
      </h5>
      <div style="margin-bottom: 20px">
        <a-button
          @click="showModal"
          :disabled="classroom.status !== 'active'"
          type="user-add"
          ><a-icon type="user-add" />{{
            $t("inscription.nouvInscri")
          }}</a-button
        >
        <a-button-group style="float: right">
          <a-button type="file-pdf" @click="generateFicheClassPDF"
            ><a-icon type="file-pdf" @click="generateFicheClassPDF" />{{
              $t("inscription.ficheParentClasse")
            }}</a-button
          >
          <a-button type="file-pdf" @click="convert"
            ><a-icon type="file-pdf" />{{
              $t("inscription.ficheClasse")
            }}</a-button
          >
        </a-button-group>
      </div>
      <a-table
        @change="tableChanged"
        :pagination="true"
        :data-source="data"
        :columns="columns"
        :loading="tableLoading"
      >
        <template slot="gender" slot-scope="text">
          {{ text == "male" ? $t("profil.garcon") : $t("profil.fille") }}
        </template>
        <div
          slot="filterDropdown"
          slot-scope="{
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            column,
          }"
          style="padding: 8px"
        >
          <a-input
            v-ant-ref="(c) => (searchInput = c)"
            :placeholder="`${$t('personnel.chercher')} ${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="
              () => handleSearch(selectedKeys, confirm, column.dataIndex)
            "
          />
          <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
            >{{ $t("recette.chercher") }}</a-button
          >
          <a-button
            size="small"
            style="width: 90px"
            @click="() => handleReset(clearFilters)"
            >{{ $t("action.reinitialiser") }}</a-button
          >
        </div>
        <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? '#108ee9' : undefined }"
        />
        <template slot="fullName" slot-scope="text, record">
          <router-link :to="`/eleve/${record._id}`" class="kit__utils__link">{{
            record.fullName
          }}</router-link>
        </template>
        <template slot="customRender" slot-scope="text, record, index, column">
          <span v-if="searchText && searchedColumn === column.dataIndex">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
            >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
                >{{ fragment }}</mark
              >
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else>{{ text }}</template>
        </template>

        <a-select
          slot="changeClassroom"
          slot-scope="text, record"
          show-search
          option-filter-prop="children"
          :disabled="changeClassRoomDisable"
          :loading="changeClassRoomDisable"
          :filter-option="filterOption"
          :placeholder="$t('liste.classes')"
          @change="(value) => changeClassRoom(value, record)"
          :default-value="classId ? classId : ''"
          class="w-100"
        >
          <a-select-option
            :value="item._id"
            v-for="item in classRoomList"
            :key="item._id"
            :disabled="item.status == 'inactive'"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-table>
      <div>
        <a-modal
          v-model="visibleModal"
          @cancel="handleCancel"
          :title="$t('all.enterInformationBelow')"
          :width="750"
          :height="1200"
          :footer="false"
        >
          <a-form :form="form" class="container" @submit="addInscription">
            <div class="row">
              <div class="col-lg-6">
                <a-form-item :label="$t('inscription.enfant')">
                  <a-select
                    show-search
                    :autoFocus="true"
                    option-filter-prop="children"
                    mode="multiple"
                    :filter-option="filterOption"
                    :placeholder="$t('inscription.enfant')"
                    v-decorator="[
                      'students',
                      {
                        rules: [
                          {
                            required: true,
                            message: $t('requis.selectionEnfant'),
                          },
                        ],
                      },
                    ]"
                  >
                    <a-select-option
                      v-for="eleve in eleves"
                      :key="eleve._id"
                      :value="eleve._id"
                    >
                      {{ eleve.firstName }} {{ eleve.lastName }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </div>
              <div class="cold-lg-6">
                <a-form-item :label="$t('all.startDate')">
                  <a-date-picker
                    format="DD/MM/YYYY"
                    :disabled-date="disabledDate"
                    @change="
                      (val) => {
                        setIncludedFees(val);
                      }
                    "
                    v-decorator="[
                      'start',
                      {
                        rules: [
                          {
                            required: false,
                          },
                        ],
                      },
                    ]"
                  />
                </a-form-item>
              </div>
            </div>
            <div class="row"></div>
            <div class="row">
              <a-divider>Selectionner le mode de paimenet </a-divider>

              <div class="col-lg-3">
                <h6 for="">{{ $t("inscription.fraisInscri") + " (dt)" }}</h6>
                <p>{{ classroom.inscription }}</p>
              </div>
              <div
                @click="changeModeP('M')"
                :class="[
                  'col-lg-4',
                  'mr-2',
                  'pt-2',
                  modePaiement === 'M'
                    ? 'custom-border-active'
                    : 'custom-border',
                ]"
              >
                <h6 for="">{{ $t("inscription.fraisMensuel") + " (dt)" }}</h6>
                <p>{{ classroom.monthly }}</p>
              </div>
              <div
                @click="changeModeP('T')"
                :class="[
                  'col-lg-4',
                  'pt-2',
                  modePaiement === 'T'
                    ? 'custom-border-active'
                    : 'custom-border',
                ]"
                v-if="!!classroom.tranches && classroom.tranches.length > 0"
              >
                <h6 for="">Frais des tranches (dt)</h6>
                <li v-for="t in classroom.tranches" :key="t.trancheNumber">
                  {{ "Tranche " + t.trancheNumber + " : " + t.amount }}
                </li>
              </div>
              <a-alert
                class="mt-2"
                :message="
                  'Le mode de paiment selectionné est ' +
                  (modePaiement === 'T' ? 'par tranches' : 'mensuelle')
                "
                type="info"
                show-icon
              />
            </div>
            <div class="row">
              <a-divider />

              <div class="col-lg-12 mb-2">
                <div v-for="(value, key, index) in includedFees" :key="key">
                  <div class="row">
                    <div class="col-6">
                      <a-form-item
                        :class="index == 0 ? 'mt-0 mb-0' : 'm-0'"
                        :label="index == 0 ? 'Frais' : ''"
                      >
                        <a-tag style="width: 100%; font-size: 15px" class="mt-1"
                          >{{ formatFeeName(key) }}
                        </a-tag>
                      </a-form-item>
                    </div>
                    <div class="col-6">
                      <a-form-item
                        :class="index == 0 ? 'mt-0 mb-0' : 'm-0'"
                        :label="index == 0 ? `Inclus` : ''"
                      >
                        <a-switch
                          :checked="includedFees[key]"
                          :key="key"
                          @change="
                            (e) => {
                              changeIncludedFees(key, e);
                            }
                          "
                        >
                          <a-icon slot="checkedChildren" type="check" />
                          <a-icon slot="unCheckedChildren" type="close" />
                        </a-switch>
                      </a-form-item>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-actions mt-0">
              <a-button
                style="width: 150px"
                type="primary"
                htmlType="submit"
                :loading="loadingInscription"
                class="mr-3"
              >
                {{ $t("action.ajouter") }}
              </a-button>
              <a-button
                key="back"
                @click="handleCancel"
                :disabled="loadingInscription"
              >
                {{ $t("action.annuler") }}
              </a-button>
            </div>
          </a-form>
        </a-modal>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import JsPDF from "jspdf";
import "jspdf-autotable";
import $ from "jquery";
import router from "@/router";
import apiClient from "@/services/axios";
import moment from "moment";
import { mapState } from "vuex";
import { PageHeader } from "ant-design-vue";
var init = true;
import { formatFeeName, dateRange } from "@/utilities/settings";

export default {
  computed: mapState(["settings", "user"]),
  components: {
    "a-page-header": PageHeader,
  },
  props: {
    classId: {
      required: true,
    },
  },
  async created() {
    this.tableLoading = true;
    const building = this.user.building.find(
      (el) => el.dbName == this.settings.activeBuilding
    );
    if (building) this.reductionType = building.reductionType || "pourcentage";

    apiClient
      .post("/classrooms/filter", {
        query: {},
        aggregation: {
          $project: {
            name: 1,
            inscription: 1,
            monthly: 1,
            tranches: 1,
            status: 1,
          },
        },
      })
      .then((res) => {
        this.classroom = res.data.find((c) => c._id == this.classId);
        this.classRoomList = res.data.filter((c) => c.status == "active");
        if (this.classroom.status == "inactive")
          this.classRoomList.push(this.classroom);
      })
      .catch((e) => {
        console.error(e);
        this.$message.error("Classe non trouvé!");
        router.go(-1);
      });

    await apiClient
      .post("/students/filter", {
        query: {
          status: "active",
        },
        aggregation: {
          $project: {
            firstName: 1,
            lastName: 1,
            classRoom: 1,
            schoolarYearsHistory: 1,
          },
        },
      })
      .then((res) => {
        res.data.forEach((elem) => {
          if (
            !elem.classRoom ||
            !elem.schoolarYearsHistory[this.settings.activeSchoolarYear]
          )
            this.eleves.push(elem);
        });
      })
      .catch((e) => {
        console.error(e);
        this.$message.error("List des élèves non trouvés");
      });

    this.getStudentTable();
    this.setIncludedFees();
  },
  data() {
    this.dateFormat = moment("DD MM-YYYY");
    return {
      tableLoading: false,
      hasDefaultReduction: false,
      defaultReduction: 0,
      classroomCount: 0,
      classroom: {},
      reductionType: "precentage",
      columns: [
        {
          title: this.$t("inscription.nom"),
          dataIndex: "fullName",
          key: "fullName",
          scopedSlots: {
            customRender: "fullName",
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          onFilter: (value, record) =>
            record.fullName
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
        {
          title: this.$t("inscription.dateNaissance"),
          dataIndex: "BirthDate",
          key: "BirthDate",
          scopedSlots: {
            customRender: "BirthDate",
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          onFilter: (value, record) =>
            record.BirthDate.toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
        {
          title: this.$t("inscription.sexe"),
          dataIndex: "gender",
          key: "gender",
          scopedSlots: {
            customRender: "gender",
          },
          filters: [
            { text: "Garçon", value: "male" },
            { text: "Fille", value: "female" },
          ],
          onFilter: (value, record) => record.gender.indexOf(value) === 0,
        },
        {
          title: this.$t("inscription.tels"),
          dataIndex: "phone",
          key: "phone",
          scopedSlots: {
            customRender: "phone",
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          onFilter: (value, record) =>
            record.phone.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
        {
          title: this.$t("inscription.parent"),
          dataIndex: "parent",
          key: "parent",
          scopedSlots: {
            customRender: "parent",
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          onFilter: (value, record) =>
            record.parent
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
        {
          title: "Changer classe",
          dataIndex: "changeClassroom",
          key: "changeClassroom",
          scopedSlots: {
            customRender: "changeClassroom",
          },
        },
      ],
      data: [],
      firstPage: true,
      selectedClass: null,
      changeMois: null,
      defaultValue: null,
      editingKey: "",
      editable: false,
      searchText: "",
      searchInput: null,
      searchedColumn: "",
      loadingInscription: false,
      visibleModal: false,
      filtredTable: [],
      form: this.$form.createForm(this),
      date: null,
      fraisInscription: null,
      fraisMensuel: null,
      eleves: [],
      modePaiement: "M",
      currentFeesRange: {},

      includedFees: {},
      changeClassRoomDisable: false,
      classRoomList: [],
    };
  },
  methods: {
    formatFeeName,
    changeIncludedFees(key, e) {
      this.includedFees[key] = e;
      this.$forceUpdate(); // Force re-render
    },
    changeModeP(v) {
      this.modePaiement = v;
      this.setIncludedFees();
    },
    setIncludedFees(value = null) {
      const activeSchoolarYear = this.settings.activeSchoolarYear;
      const schoolarYears = this.settings.schoolarYears;
      let start = value;
      let end = null;
      schoolarYears.forEach((elem) => {
        if (elem._id == activeSchoolarYear) {
          end = elem.end;
          if (!start) start = elem.start;
        }
      });

      const range = this.dateRange(
        moment(start).format("YYYY-MM-DD"),
        moment(end).format("YYYY-MM-DD")
      );

      this.includedFees = {};

      if (this.classroom.monthly && this.modePaiement == "M") {
        range.forEach((month) => {
          this.includedFees[month] = true;
        });
      }
      if (this.classroom.tranches && this.modePaiement == "T") {
        this.classroom.tranches.forEach((tranche) => {
          this.includedFees[tranche.trancheNumber] = true;
        });
      }
    },

    dateRange,
    async getStudentTable() {
      let match = {};
      match[`schoolarYearsHistory.${this.settings.activeSchoolarYear}`] =
        this.classId;
      await apiClient
        .post("/students/filter", {
          query: { status: "active" },
          aggregation: [
            {
              $match: match,
            },
            {
              $lookup: {
                from: "parents",
                localField: "parent",
                foreignField: "_id",
                as: "parent",
              },
            },
            {
              $project: {
                _id: 1,
                firstName: 1,
                lastName: 1,
                parent: {
                  father: 1,
                  mother: 1,
                },
                gender: 1,
                BirthDate: 1,
                name: 1,
                schoolarYearsHistory: 1,
              },
            },
          ],
        })
        .then((res) => {
          this.classroomCount = res.data.length;
          this.data = res.data.map((elem) => ({
            key: elem._id,
            _id: elem._id,
            fullName: elem.firstName + " " + elem.lastName,
            parent:
              elem.parent[0].father.firstName +
              " " +
              elem.parent[0].father.lastName +
              "/" +
              elem.parent[0].mother.firstName +
              " " +
              elem.parent[0].mother.lastName,
            BirthDate: moment(elem.BirthDate).format("DD/MM/YYYY"),
            gender: elem.gender,
            phone:
              elem.parent[0].father.phone + "/" + elem.parent[0].mother.phone,
            schoolarYearsHistory: elem.schoolarYearsHistory,
          }));
          this.filtredTable = this.data.map((item) => ({ ...item }));

          this.cacheData = this.data.map((item) => ({ ...item }));
        });

      this.tableLoading = false;
      //this.filtredTable = this.activeData;
    },
    tableChanged(pagination, filters, sorter, extra) {
      this.filtredTable = extra.currentDataSource;
    },
    moment,
    showModal() {
      this.visibleModal = true;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    addInscription(e) {
      this.loadingInscription = true;
      e.preventDefault();
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          const activeSchoolarYear = this.settings.activeSchoolarYear;
          const schoolarYears = this.settings.schoolarYears;
          let data = { ...values };
          schoolarYears.forEach((elem) => {
            if (elem._id == activeSchoolarYear) {
              data.end = elem.end;
              if (!values.start) data.start = elem.start;
            }
          });
          delete data.fraisMensuel;
          delete data.fraisInscription;

          data.typeAS = "S";
          data.classroom = this.classId;
          data.defaultReduction = this.defaultReduction;
          data.mode = this.modePaiement;
          const filteredRepReduction = Object.keys(this.includedFees)
            .filter((key) => this.includedFees[key] === true)
            .reduce((obj, key) => {
              obj[key] = false;
              return obj;
            }, {});
          console.log(values);
          let repReduction = { ...filteredRepReduction };
          data.mode = this.modePaiement;
          data.repReduction = repReduction;
          if (this.modePaiement == "M") data.months = filteredRepReduction;
          else data.tranches = filteredRepReduction;
          //default rep reduction is 0
          data.repReduction = Object.keys(filteredRepReduction).reduce(
            (obj, key) => {
              obj[key] = 0;
              return obj;
            },
            {}
          );
          data.repReduction["inscription"] = 0;

          apiClient
            .put(`/payment/registerStudents`, data)
            .then(async (res) => {
              await this.getStudentTable();
              this.handleCancel();
              this.eleves = this.eleves.filter(
                (elem) => !values.students.includes(elem._id)
              );
              this.$message.success(this.$t("success.inscriAjout"));
            })
            .catch((e) => {
              this.$message.error(this.$t("error.inscriEchoue"));
              console.log(e);
            })
            .finally(() => {
              this.loadingInscription = false;
            });
        } else {
          this.loadingInscription = false;
        }
      });
    },
    handleCancel() {
      this.form.resetFields();
      this.defaultReduction = 0;
      this.hasDefaultReduction = false;
      this.visibleModal = false;
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },

    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
    handleChange(value, key, column) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },
    async changeClassRoom(calssRoomId, record) {
      this.changeClassRoomDisable = true;

      const selectedClassroomId = calssRoomId;
      const student = record;
      const oldClassRoom = this.classroom;

      await apiClient
        .patch("/payment/changeclass/" + student._id, {
          oldClassroomId: oldClassRoom._id,
          newClassroomId: selectedClassroomId,
        })
        .then((res) => {
          const newClassRoom = this.classRoomList.find(
            (c) => c._id == selectedClassroomId
          );
          this.$message.success(
            this.$t("success.eleveDemeangeAuClasse", {
              name: newClassRoom.name,
            })
          );
          this.getStudentTable();
          this.setIncludedFees();
        })
        .catch((error) => {
          console.log(error);
          let errorMessage = "Une erreur inattendue s'est produite.";

          if (error.response && error.response.status === 409) {
            errorMessage = error.response.data.message;
          }
          this.$message.error(errorMessage);
        })
        .finally(() => {
          this.changeClassRoomDisable = false;

          this.loadingClassroomChange = false;
        });
      //////
    },

    disabledDate(current) {
      const activeSchoolarYear = this.settings.activeSchoolarYear;
      const schoolarYears = this.settings.schoolarYears;
      const schoolarYear = schoolarYears.find(
        (elem) => elem._id == activeSchoolarYear
      );
      const { start, end } = schoolarYear;
      return (current && current.isAfter(end)) || current.isBefore(start);
    },
    convert() {
      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }
      var doc = new JsPDF();
      doc.setFont("Amiri");
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();

      today = dd + "/" + mm + "/" + yyyy;
      var className = this.classroom.name;
      doc.setFontSize(15);
      doc.text(92, 60, className);
      doc.setFontSize(20);
      doc.text(85, 50, "Fiche class");
      doc.setFontSize(12);
      doc.text(150, 25, "Imprimé le : " + " " + today);
      doc.setFontSize(40);
      doc.addImage(imgData, "JPEG", 10, 10, 40, 40);
      var arr = [];
      const array = [];
      let garcon = 0;
      let fille = 0;
      for (let i = 0; i < this.filtredTable.length; i++)
        this.filtredTable[i].gender === "male" ? garcon++ : fille++;
      arr.push({
        rowSpan: garcon,
        content: "Garçon",
        styles: { valign: "middle" },
      });
      for (let i = 0; i < this.filtredTable.length; i++) {
        if (this.filtredTable[i].gender === "male") {
          arr.push(this.filtredTable[i].fullName);
          array.push(arr);
          arr = [];
        }
      }
      arr.push({
        rowSpan: fille,
        content: "Fille",
        styles: { valign: "middle" },
      });
      for (let i = 0; i < this.filtredTable.length; i++) {
        if (this.filtredTable[i].gender === "female") {
          arr.push(this.filtredTable[i].fullName);
          array.push(arr);
          arr = [];
        }
      }
      doc.autoTable({
        theme: "grid",
        styles: { font: "Amiri" },
        head: [["Sexe", "Nom & Prénom"]],
        body: array,
        margin: { top: 70 },
        foot: [["Total", this.filtredTable.length]],
      });
      //page numbering
      const pages = doc.internal.getNumberOfPages();
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      doc.setFontSize(8); //Optional

      for (let j = 1; j < pages + 1; j++) {
        let horizontalPos = pageWidth / 2;
        let verticalPos = pageHeight - 10;
        doc.setPage(j);
        doc.text(`Page ${j} / ${pages}`, horizontalPos, verticalPos, {
          align: "center",
        });
      }
      //end page numbering
      doc.save("Fiche_Classe " + className + ".pdf");
    },
    generateFicheClassPDF() {
      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }
      const doc = new JsPDF();
      doc.setFont("Amiri");
      var date = new Date();
      var titre = "Dossier médicale";
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };

      var className = this.classroom.name;
      doc.setFontSize(15);
      doc.text(89, 60, className);
      doc.setFontSize(20);
      doc.text(85, 50, "Fiche parent");
      doc.setFontSize(40);
      doc.addImage(imgData, "JPEG", 10, 10, 40, 40);
      doc.setFontSize(12);
      doc.text(150, 40, date.toLocaleDateString("fr-FR", options));
      var arr = [];
      let counter = 1;
      const array = [];
      for (let i = 0; i < this.filtredTable.length; i++) {
        arr.push(counter);
        arr.push(this.filtredTable[i].fullName);
        arr.push(this.filtredTable[i].BirthDate);
        arr.push(this.filtredTable[i].parent);
        arr.push(this.filtredTable[i].phone);
        array.push(arr);
        arr = [];
        counter++;
      }
      doc.autoTable({
        theme: "grid",
        styles: { font: "Amiri" },
        head: [
          ["Num", "Nom complet", "Date de naissance", "Parent", "Téléphone"],
        ],
        body: array,
        margin: { top: 70 },
      });
      doc.save("Fiche_Classe_parent.pdf");
    },
  },
};
</script>
<style scoped>
.custom-border {
  border: 1px solid #6c757d;
  border-radius: 0.25rem;
  cursor: pointer;
}
.custom-border-active {
  border: 3px solid #22bb33;
  border-radius: 0.25rem;
  cursor: pointer;
}
</style>
